@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Cairo:wght@200;300;400;500;600;700;800;900&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Kanit:ital,wght@0,100;0,700;1,600&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600&family=Piazzolla:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,800;0,900;1,100;1,800&family=Roboto:ital,wght@0,700;0,900;1,100;1,500&family=Space+Grotesk:wght@300;400;500;600;700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&family=Work+Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ysabeau+SC:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  /* font-family: "Aclonica", sans-serif;
  font-family: "Cairo", sans-serif;
  font-family: "DM Sans", sans-serif;
  font-family: "Kanit", sans-serif;
  font-family: "Lato", sans-serif;
  font-family: "Merriweather", serif;
  font-family: "Oswald", sans-serif;
  font-family: "Piazzolla", serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  font-family: "Space Grotesk", sans-serif;
  font-family: "Titillium Web", sans-serif;
  font-family: "Work Sans", sans-serif;
  font-family: "Ysabeau SC", sans-serif; */
}
.field-outer input {
  height: 48px !important;
}
.css-zipzvv {
  white-space: inherit !important;
}
.tac-tic {
  width: 275px;
}
.accordion {
  --bs-accordion-btn-focus-border-color: unset !important;
  --bs-accordion-btn-focus-box-shadow: unset !important;
}
::-webkit-scrollbar {
  width: 14px;
}
.form-outline .form-control {
  width: 300px;
  border-radius: 0px;
  height: 51px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
}
.fghi {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50px, -50px);
}
.form-select {
  font-weight: 600 !important;
}
.right-0 {
  width: 75%;
}
.search-drive {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}
.section-intro {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0px 20px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fa3230;
  border-radius: 10px;
}

.vohrg {
  width: 100%;
  height: 530px;
  object-fit: cover;
}

.contact-info p {
  padding: 15px;
  background: #004aad;
  color: white;
  border-radius: 5px;
}

.destination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  font-size: 31px !important;
  font-weight: 800 !important;
}

h3 {
  font-size: 25px !important;
  font-weight: 700 !important;
}

.destination.desti:hover {
  transform: scaleY(-10px);
  cursor: pointer;
}

.drop-d {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 11px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.drop-d:hover {
  background: #004aad;
  color: white;
  cursor: pointer;
}

.navigation-menu li a:hover {
  color: #ff3133;
}

.drop-d h5 {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background: black;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
}

.diff-s input {
  height: 44px;
  border: 1px solid #dee2e6;
  outline-width: 0px;
  padding: 11px;
  width: 251px;
  border-radius: 5px;
}

.diff-ss button {
  height: 44px;
  border: 1px solid black;
  outline-width: 0px;
  padding: 5px;
  width: 251px;
  border-radius: 5px;
}

.pacakege {
  /* position: absolute;
  width: 82%;
  right: 100px;
  top: 251px;
  background: #004aad;
  padding: 35px;
  border-radius: 11px;
  filter: drop-shadow(5px 5px 10px #000); */
  position: relative;
  /* width: 82%; */
  /* right: 100px; */
  top: -105px;
  background: #004aad;
  padding: 35px;
  border-radius: 11px;
  filter: drop-shadow(5px 5px 10px #000);
}

.travel-pacakeg {
  font-size: 30px;
  font-weight: 700;
  font-family: math;
}

.modal-footer {
  display: unset !important;
}

.searchWrapper {
  display: flex;
  border: 2px solid #00000047;
  width: fit-content;
  align-items: center;
}

/* .table-bordered> :not(caption)>*>* {
  border-width: 0 0px !important;
} */

.table-striped thead tr {
  background-color: #29ccef;
  color: white;
}

.table > tbody {
  vertical-align: top !important;
}

.footer {
  background-color: #004aad;
  padding: 20px;
  text-align: center;
  color: white;
}

.footer-lk-o {
  padding-top: 20px;
  line-height: 12px;
}

.btn.btn-primary {
  background-color: #fa3230;
  border-color: #fa3230;
}

a {
  text-decoration: none !important;
}
.icons {
  font-size: 40px;
}
.icons svg {
  border: 2px solid white;
  border-radius: 100%;
  padding: 4px;
}
.icons svg:hover {
  background-color: white;
  color: #004aad;
}

/* .home-0 {
  margin-top: 180px;
} */

.travel {
  padding: 20px;
}

.travel h1 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 800;
}

.b-travel .button {
  display: inline-block;
  background-color: #004aad;
  color: #fff;
  border-radius: 30px;
  padding: 8px 25px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.book {
  padding: 21px;
  border-radius: 6px;
  text-align: center;
  height: 250px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.book-co p {
  padding: 17px;
}

.mt-30 {
  margin-top: 70px;
  margin-bottom: 30px;
}

.book:hover {
  background-color: #004aad;
  color: white;
  cursor: pointer;
  transform: translateY(-10px);
}

/* .out-00 {
  background-image: url("../public/Image/hs2bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 600px;
} */
.hinfobx {
  background: #004aad;
  width: 100%;
  height: auto;
  float: left;
  margin-top: 71px;
  padding: 45px;
}

.hinfobx h2 {
  font-family: "Playfair Display", serif;
  font-size: 38px;
  color: #333;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.hinfobx p {
  font-family: "Archivo Narrow", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: white;
  width: 100%;
  float: left;
  text-align: center;
}

p {
  margin: 0 0 10px;
}

.card-blog {
  background: #fff;
}

.card-img {
  width: 100%;
}

.card-blog .card-blog-body {
  padding: 25px 25px 30px 40px;
}

.card-blog .card-blog-body h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.card-blog .card-blog-body h4 {
  font-size: 19px;
  margin-bottom: 15px;
}

.card-blog-info {
  margin-bottom: 15px;
}

.card-blog-info li {
  display: inline-block;
  font-size: 14px;
  color: #888888;
  margin-right: 25px;
}

.card-blog-info li a {
  color: #888888;
}

.card-blog-info li span {
  margin-right: 5px;
}

.card-blog-info li:last-child {
  margin-right: 0;
}

.pb-90px {
  /* padding-bottom: 90px; */
  padding-top: 75px;
}

.section-intro-img {
  margin-bottom: 10px;
}

.destination .text .one {
  width: calc(100% - 70px);
}

.one h3 a {
  color: black;
}

.destination .text h3 {
  font-size: 18px;
  font-weight: 500;
}

.destination .rate .svg-inline--fa.fa-star {
  font-size: 12px;
  color: #c2723d;
  margin: 0;
}

.destination .text .rate span {
  padding-left: 2px;
  font-size: 10px;
}

.destination .text .two {
  width: 100px;
  text-align: right;
}

.destination .text .bottom-area {
  margin-bottom: 0;
}

.destination .text .bottom-area span {
  font-size: 14px;
}

.destination .text .bottom-area span:last-child a {
  background: #004aad;
  color: #fff;
  border-radius: 4px;
  padding: 6px 15px;
}

.destination .text .bottom-area span:last-child a:hover {
  background: #fa3230;
  color: #fff;
}

.ml-auto {
  margin-left: auto !important;
}

.popular-reverse {
  display: flex;
  flex-direction: column-reverse;
}

/* .out-02 {
  background-image: url("../public/Image/hs3bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 600px;
} */
.hinfobx-0p {
  background: #c2723d;
  width: 100%;
  height: auto;
  float: left;
  margin: 0;
  padding: 40px;
  margin-top: 70px;
  text-align: center;
}

.hinfobx-0p h2 {
  font-family: "Playfair Display", serif;
  font-size: 38px;
  color: #333;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  color: white;
}

/* .hinfobxout-9 {
  margin: 0 auto;
  float: none !important;
  display: table;
} */

.jungal {
  padding: 50px 0px 30px;
}

.read-op {
  font-size: 35px;
  margin-bottom: 20px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  /* color: #06c; */
  font-family: Poppins, sans-serif;
}

.img-responsive {
  margin-top: 13px;
  border-radius: 10px;
  height: 180px;
  width: 100%;
}

.car-text h3 a {
  color: black;
}

.listing {
  margin-bottom: 20px;
  position: relative;
  background: #fff;
  border: 0 solid #eee;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 100%;
  display: block;
  height: auto;
  padding: 15px;
  margin-top: 25px;
}

.booking {
  background-image: url("/public/Taxi/deepamcabs_bg.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  margin-top: 60px;
}

.flow-wrap {
  display: flex;
  gap: 1px;
  padding-left: 0px;
  /* justify-content: center; */
}

.flow-wrap li {
  background-color: #152736;
  border: 1px solid #152736;
  padding: 10px;
  border-radius: 3px;
}

.local-trap a {
  color: white;
}

/* .tab-content {
 position: absolute;
 left:33%;
  background-color: #fff;
  float: left;
  padding: 10px 30px;
  margin-top: -16px;
} */
.trip-type-frm label {
  display: inline-block;
  color: #000;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 10px 8px 0 5px;
  font-weight: 700;
}

.radio-wrapper input {
  top: 2px;
  position: relative;
  margin-right: 5px;
}

/* span {
  position: static;
  display: inline-block;
  font-size: 15px;
  /* color: #004aad; */
/* font-weight: 600; */
/* } */

.text-danger {
  color: #a94442;
}

.trip-type-frm input[type="text"] {
  display: block;
  /* height: 35px !important; */
  padding: 0 15px;
  width: 100%;
  font-weight: 500;
  color: #000;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #bfc0c1;
  background-color: #f6f6f6;
  font-weight: 700;
}

.pac-target-input:not(:-webkit-autofill) {
  animation-name: endBrowserAutofill;
}

.search-btn {
  display: block;
  border: none;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  margin: 15px 0 30px;
  float: left;
  font-size: 19px;
  background-color: #fa3230;
}

.booking-aap {
  padding: 35px;
}

.blog_item_img {
  position: relative;
}

.blog_item {
  margin-bottom: 50px;
}

.blog_item_img .blog_item_date {
  position: absolute;
  /* bottom: -10px; */
  /* left: 10px; */
  display: block;
  color: #fff;
  background: #004aad;
  /* padding: 8px 15px; */
  border-radius: 5px;
  bottom: -20px;
  left: 40px;
  padding: 13px 30px;
}

.blog_details {
  padding: 60px 30px 35px 35px;
  box-shadow: 0px 10px 20px 0px rgb(221 221 221 / 30%);
}

.blog_details a h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: black;
}

.blog_details p {
  margin-bottom: 30px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.blog-info-link li {
  float: left;
  font-size: 14px;
}

.blog-info-link li a {
  color: #999999;
}

.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

.input-group-append {
  display: flex;
}

.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

.blog_right_sidebar .search_widget .input-group button i {
  font-size: 14px;
  color: #999999;
}

.button.rounded-0 {
  display: inline-block;
  background-color: #004aad;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 12px 45px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 8px;
}

.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
  margin-top: 16px;
}

.list.cat-list {
  padding-left: 0px;
}

.media.post_item {
  display: flex;
  align-items: flex-start;
  margin-top: 11px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

.ftco-section {
  padding: 0em 0em 4em;
  position: relative;
}

.contact-section .contact-info p a {
  color: #1a1a1a;
}

.block-9 .form-control {
  outline: none !important;
  box-shadow: none !important;
  height: 52px !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 15px;
  border-radius: 0px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control-2 {
  outline: none !important;
  box-shadow: none !important;
  background: #fff !important;
  color: #000000 !important;
  font-size: 15px;
  border-radius: 0px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}

.block-9 {
  padding-top: 30px;
}

@media only screen and (max-width: 600px) {
  .travel {
    padding: 12px;
    margin-top: -72px;
  }

  #login-form-wrap {
    background-color: #fff;
    width: 90% !important;
    padding: 12px;
    margin: 75px auto;
    text-align: center;
    padding: 20px 15px 13px 15px !important;
    border-radius: 4px;
    box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
  }

  .destination {
    flex-direction: column;
  }

  .vohrg {
    height: 381px;
  }

  .bg-image {
    padding: 5px !important;
  }

  .footer-lk-o {
    padding-top: 20px;
    line-height: 20px;
  }

  .inner-content-listing-desc {
    padding: 40px 0px !important;
  }

  .brand-name {
    width: 70% !important;
  }

  .travel h1 {
    text-transform: capitalize;
    margin-bottom: 15px;
    font-weight: 800;
  }

  .header-image img {
    height: 130px !important;
    margin-top: 58px;
  }

  .blog_item_img .blog_item_date {
    border-radius: 5px;
    bottom: -20px;
    left: 40px;
    padding: 1px 25px;
  }

  .read-op {
    font-size: 32px;
  }

  .listing {
    height: auto;
  }

  .form-control-2 {
    width: 100%;
  }

  .local-trap a {
    color: white;
    font-size: 8px;
  }

  .booking-aap {
    padding: 6px;
  }

  .flow-wrap {
    gap: 0px;
  }

  .hinfobx {
    margin-top: 52px;
  }

  .hinfobxout-9 {
    display: block !important;
  }

  /* .ftco-section {
    padding: 6em 0;
  } */
  .pb-90px {
    padding-bottom: 1px;
    padding-top: 45px;
  }

  .tab-content {
    position: unset;
  }

  .booking {
    height: 570px;
    margin-top: 65px;
  }

  .hero-wrap.js-fullheight {
    height: 300px !important;
  }
  .header-s {
    flex-direction: column;
  }
  .wraperr-f {
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
  }
  /* ============== */
  .left-right {
    flex-direction: column;
  }
  .product-spa.mx-5 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .left-0 {
    width: auto !important;
  }
  .search-drive {
    flex-direction: column;
  }
  .search-drive .spa-item {
    width: 90%;
  }
  .right-0 {
    width: auto !important;
  }
  .rhlepd {
    flex-direction: column;
  }
  .rhlepd .price-wild {
    text-align: start !important;
  }
  .wild-img-content .active-b {
    position: relative !important;
  }
  .wild-img img {
    width: 100% !important;
  }
  /* ============ */
}

.wr-0 {
  font-weight: bolder;
  font-family: sans-serif;
}

.listing-wro {
  color: darkslateblue;
  font-size: 18px;
  font-family: sans-serif;
}

.over-taxi {
  font-size: 20px;
  color: darkblue;
  /* font-family: sans-serif; */
  font-weight: 600;
}

.ant-picker-input input {
  padding: 0 15px !important;
  height: 35px !important;
  color: #000 !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-picker-focused {
  border-color: unset !important;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}

.form-control-inner {
  box-shadow: none;
  background: 0 0;
  border: 1px solid #d2d1d1;
  height: 40px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  width: 100%;
  padding: 2px 10px 0 10px;
  border-radius: 5px;
  outline-width: 0px;
}

.taxi-booking {
  background-color: #004aad;
}

.pb-2.nd-2 {
  font-size: 18px;
  font-weight: 500;
}

.form-control {
  box-shadow: none;
  background: 0 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 30px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
}

.r-lg-0 {
  top: 120px;
  position: sticky;
}

.btn.btn-outline {
  background: 0 0;
  border: 1px solid gray;
  font-size: 14px;
  transition: 0.3s;
}

/* .owl-carousel.owl-loaded {
  display: block;
  margin-top: 60px !important;
} */

.form-select {
  border: 1px solid darkgray !important;
  padding: 10px !important;
  border-radius: 5px !important;
  outline-width: 0px !important;
  width: 100% !important;
}

.paginationBttns {
  width: 97%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.paginationBttns a {
  padding: 6px 10px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #29ccef;
  color: #29ccef;
  cursor: pointer;
  text-decoration: none;
  /* float: left; */
}

.paginationBttns a:hover {
  color: white;
  background-color: #29ccef;
}

.paginationActive a {
  color: white;
  background-color: #29ccef;
}

/* .mt-2,mb-3 {
  margin-bottom: "0px" !important;
} */

/* 17-08-2023 */
.lion {
  display: flex;
  align-items: baseline;
}
.rhlepd {
  /* display: flex; */
  gap: 20px;
  color: black;
  line-height: 18px;
}
.Activities-o p {
  font-size: 20px;
  font-weight: 600;
  color: #fa3230;
}
.face-act {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
}
.rehped-0 {
  padding: 0 15px 0 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #212121;
}
.rehped-0 span {
  padding-right: 6px;
}
.price-0 {
  font-size: 16px;
  line-height: 31px;
  font-weight: 600;
}
.wild-img-content {
  /* padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 15px;
  padding-left: 15px; */
  padding: 20px;
  border-right: 1px solid #ccc;
  position: relative;
  text-align: justify;
}
.price-wild {
  padding: 26px 0 26px 0;
  padding-right: 15px;
  padding-left: 15px;
}
.active-b {
  margin-top: 27px;
}
.rhlep {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  background: white;
  margin: 30px 0 20px;
  border-radius: 5px;
}
.kabini-wrap p {
  font-size: 36px;
  font-weight: 700;
  padding-top: 55px;
}
.lodge-h-j ul {
  display: flex;
}
.page-wrap {
  margin: 6px 15px 0 0;
  font-size: 16px;
  font-weight: 600;
  color: #383838;
  text-decoration: none;
  border-bottom: 2px solid #ccc;
  padding: 0 0 2px 0;
  cursor: pointer;
}
.slick-dots li {
  width: 125px !important;
  height: 0px !important;
}
.over-view {
  padding-top: 65px;
}
.cautious {
  font-size: 25px;
  font-weight: 600;
}
.wpcf7-text {
  width: 100%;
  height: 30px;
  float: left;
  margin-bottom: 20px;
  padding: 0;
  border: solid #ccc;
  border-width: 0 0 1px 0;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  outline-width: 0px;
}
.thumbnails {
  justify-content: center;
}
.contact-wrap h5 {
  font-size: 22px;
  color: red;
  font-weight: 600;
}
.Lodge-wraper a {
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  color: #333333;
  font-weight: 600;
}
.other-resort {
  margin-bottom: 10px;
}
.price-0 > strong {
  color: #999;
  text-decoration: line-through;
  padding-right: 10px;
}
.money-lodge .price-0 {
  font-size: 25px;
  line-height: 10px;
  font-weight: 700;
}
.items-pro {
  display: flex;
  justify-content: space-between;
}
.items-pro-wrap .kjhg {
  font-family: "Lato", sans-serif;
  font-size: 23px;
  font-weight: 400;
  color: #212121;
  margin: 15px 0;
}
.amee ul {
  display: flex;
  padding-left: 0px;
  margin: 12px 0px;
}
.inac {
  padding: 0 10px 5px 10px;
}
.styles-module_dark__xNqje {
  background: #004aad !important;
}
.tooltip-img {
  height: 25px;
  width: 23px;
}
.product__images {
  display: flex;
  margin: 10px 0px;
  gap: 11px;
}
.img__item-09 {
  border-bottom: 3px solid red;
}

/* Invoice */

.Invoice_0 {
  display: flex;
  /* gap: 30px; */
  justify-content: space-between;
  align-items: center;
  font-size: small;
}

.Invoice_0 span {
  font-weight: 600;
}

.Invoice_0 img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.Invoice_0 h5 {
  font-weight: 600;
}

.invoice_012 p {
  margin-bottom: 0px !important;
}

.iahsdijah {
  display: flex;
  /* gap: 30px; */
  justify-content: space-between;
  align-items: end;
  font-size: small;
  margin-bottom: 20px;
}

.iahsdijah h6 {
  font-weight: 600;
}

.iahsdijah span {
  font-weight: 600;
}

.invoice-table-head thead th {
  background: #004aad;
  color: #fff;
}