.ad-lodge-0 {
  margin-top: 60px;
}
.logde-op h2 {
  font-weight: 700;
}
.logde-op-00 {
  border: 1px solid #004aad;
  padding: 10px;
  color: white;
  background-color: #004aad;
  font-size: 18px;
  border-radius: 5px;
}
.title-90 {
  border: 1px solid darkgray;
  padding: 5px;
  border-radius: 5px;
  outline-width: 0px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.nd-2 {
  font-size: 18px;
}
.de-lod {
  padding: 10px 20px;
  overflow: hidden;
  overflow-x: scroll;
}
.searchWrapper {
  width: fit-content;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
  margin-bottom: 11px;
  border-radius: 50px;
}
.searchWrapper .textsearch {
  outline-width: 0px;
  padding: 5px;
  width: 300px;
  border-radius: 50px;
  height: 51px;
}
#BsSearch {
  display: none;
}

.loader {
  border: 10px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #3498db; /* Blue border */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 3s linear infinite; /* Animation for rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scrolll_bar {
  overflow: hidden;
  overflow-x: scroll;
}
