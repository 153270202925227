.sidebar {
  margin: 0px 0px;
  padding: 0;
  width: 240px;
  background: #004aad;
  position: sticky;
  top: 0px;
  padding-left: 3px;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  max-height: 100vh;
}

/* Sidebar links */
.sidebar a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  /* font-weight: 500; */
}

/* div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
} */
.sidebar a:hover {
  background: red;
  margin-right: 5px;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.a-ele {
  margin: 10px 0px;
  /* border-radius: 40px; */
  padding: 6px;
  font-size: 18px;
}
.active2 {
  background-color: red;
  color: #fff;
}
.sidebar ul {
  padding-left: unset !important;
}
.sidebar span {
  display: flex;
}
.a-ele:hover {
  background-color: red;
}
.graphics {
  margin-top: -40px;
  background-color: black;
}
