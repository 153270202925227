.left-right {
  display: flex;
  gap: 25px;
}

.categories {
  border: 1px solid white;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.p-0 li {
  background: #004aad;
  margin: 10px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid;
  padding: 7px;
  color: white;
  border-color: #004aad;

}

.search-pro input {
  outline-width: 0px;
  border: 1px solid;
  padding: 6px 20px;
  border-radius: 4px;
  border-color: darkgray;
}

.product-diff {
  margin-right: 11px;
}

#cb1 {
  accent-color: #ff48a5;
}

.check-spa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-0 {
  width: 25%;
  /* position: sticky;
  top: 51px;
  height: 100px; */
}

.product-90 {
  background-color: white;
}

.spa-item {
  width: 25%;
}

.dif-img {
  width: 100%;

  height: 500px;
}

.dif-img-1 {
  width: 100%;

  height: 250px;
}

.pet-title h2 {
  color: #e84141;
  font-size: 40px;
  font-weight: 600;
}

.pet {
  position: relative;
}

.pet-title {
  position: relative;
}

.see-all {
  position: absolute;
  bottom: 30px;
  right: 10px;
  border: 1px solid;
  background-color: white;
  border-color: white;
  padding: 10px;
  border-radius: 5px;
}

.see-all a {
  color: black;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
}

.spa-feat ul {
  display: flex;
}

.spa-all-images {
  position: relative;
}

.tab-feature {
  font-size: 21px;
  font-weight: 600;
  padding: 10px 25px;
  color: white;
}

.feature-details {
  display: flex;
  justify-content: space-between;
  padding: 21px;
}

.feature-details .body-diff {
  width: 80%;
}

.feture-valide {
  display: flex;
  gap: 21px;
}

.free-diff {
  font-size: 16px;
  font-weight: 500;
}

.free-diff span {
  color: red;
}

.feature-details-spa {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.feature {
  margin: 25px;
  background-color: white;
}

.iop-0 {
  margin: 25px;
  display: flex;
  justify-content: end;
}

.iop-d {
  border: 1px solid #ff48a5;
  height: 40px;
  padding: 5px;
  color: #ff48a5;
}
.iop-d a {
  text-decoration: none;
  color: #ff48a5;
}
.discount-p {
  padding: 0px 5px;
  font-size: 17px;
  color: white;
  border-radius: 2px;
  background-color: #ff48a5;
}

.add-sspa {
  border: 1px solid #ff48a5;
  border-radius: 10px;
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff48a5;
  color: white;
  font-size: 17px;
  font-weight: 600;
}

.spa-feat {
  background-color: #303950;
}

.active-spa {
  background-color: white;
  border-top: 2px solid #ff48a5;
  color: #e84141;
}

.sspa-incre {
  display: flex;
  justify-content: space-between;
  gap: 21px;
}

.spa-plus {
  border: 1px solid #ff48a5;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ff48a5;
  color: white;
}

.spa-plus-1 {
  font-size: 20px;
  font-weight: 600;
}

.efrig {
  padding: 20px;
  font-size: 20px;
  text-align: justify;
}

.margin-bottom-xs {
  margin-bottom: 2px !important;
}

.card-main__des {
  color: black;
}

.txt-brand-primary {
  color: #ef534e !important;
}

.section-p {
  margin-top: 12px;
}

.p-0 li:hover {
  color: #ff48a5;
}

.date-spa {
  border: 1px solid #49505763;
  height: 51px;
  border-radius: 5px;
  width: 401px;
  font-size: 20px;
  padding: 7px;
  outline-width: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0;
}

.time-spa {
  display: flex;
  justify-content: center;
}

.order-details {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 10px;
}

.book-cart {
  margin: 6px;
  position: sticky;
  top: 22px;
  background-color: white;
  padding: 5px 5px 15px 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.book-f {
  background-color: #303950;
  padding: 17px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.title-p {
  font-size: 18px;
  font-weight: 500;
}

.title-pp {
  font-size: 20px;
  font-weight: 600;
}

.freee .form-select {
  border: 1px solid #49505763;
  height: 51px;
  border-radius: 5px;
  width: 401px;
  font-size: 20px;
  padding: 7px;
  outline-width: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
}
