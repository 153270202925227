/* Hotel Filter Page */

p {
    margin-bottom: 0px !important;
  }
  
  .filter-check-input {
    border: 1px solid #000000a6 !important;
  }
  
  .demo-contain {
    position: sticky;
    top: 65px;
  }
  
  .filter-check-label {
    letter-spacing: 1px;
  }
  
  .search-btn {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
  }
  
  .sadfjsdjfdgfgh {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
  }
  
  .flight-booking {
    padding-top: 100px;
    padding-bottom: 10px;
    justify-content: space-between;
    display: flex;
    background-color: unset;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .bus-booking {
     display: flex;
     justify-content:center;
  }
  .arunbega{
    color: black;
  }
  .arunbega1{
    color: white;
  }
  .hotl-booking {
    padding-top: 100px;
    padding-bottom: 10px;
    justify-content: center;
    display: flex;
    background-color: unset;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .range-btn {
    border: none;
    border-radius: 4px;
    background-color: #8b3eea;
    color: white;
    height: 36px;
  }
  
  .all-fliter-head {
    background: #004aad;
    height: 65px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .hotel-filter-img {
    width: auto;
    height: 245px;
  }
  
  .hotel-filter-head {
    color: #3072b6;
    font-weight: 600;
    margin-bottom: 0px;
  }
  
  .hotel-benfit {
    border: 1px solid;
    padding: 3px;
    font-size: 14px;
    border-radius: 10px;
  }
  
  .availability-btn {
    border: none;
    background: #1d961a;
    color: #fff;
    padding: 9px;
    border-radius: 10px;
    letter-spacing: 1px;
  }
  
  .filter-hotels {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 25px;
  }
  
  /* hotel filter page end */
  
  /* Flight Filter Start*/
  .css-187mznn-MuiSlider-root {
    border-radius: 12px;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: #3072b6 !important;
    -webkit-tap-highlight-color: transparent;
    height: 4px;
    width: 100%;
    padding: 13px 0;
  }
  
  .kVtMtA {
    position: absolute;
    top: calc(50% - 17.5px);
    height: 58px !important;
    width: 15px !important;
    background: #5a97d8 !important;
    border-radius: 0px !important;
    box-shadow: 0 0 5px 0 #3a76b8 !important;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: 5px;
    right: initial;
    top: 0px !important;
    border: 1px solid #00000067 !important;
  }
  
  .bhRnqO {
    position: absolute;
    top: calc(50% - 17.5px);
    height: 58px !important;
    width: 15px !important;
    background: #5a97d8 !important;
    border-radius: 0px !important;
    box-shadow: 0 0 5px 0 #3a76b8 !important;
    z-index: 10;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    left: initial;
    right: 5px;
    top: 0px !important;
    border: 1px solid #00000067 !important;
  }
  
  .bghjHX {
    overflow: hidden;
    margin: 0 20px;
    border: 1px solid #00000067 !important;
  }
  
  .flight-img {
    height: 40px;
  }
  
  .flight-book {
    border: none;
    background: #004AAD !important;
    color: #fff;
    height: 45px;
    width: 110px;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }
  .fog{
    text-align:center;
    /* margin-left: 50px; */
    border: 1px solid;
    padding: 4px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  .gradient-box {
    background: linear-gradient(to right, #004aad,#004aad, #004aad,#f9fafa,#f9fafa);
  }
  .gradient-box1 {
    background: linear-gradient(to left, #004aad, #f9fafa,#f9fafa,#f9fafa);
  }
   .gradient-box2 {
    background: linear-gradient(to bottom, #6ea4eb,#f9fafa,#f9fafa, #f9fafa,#f9fafa,#f9fafa,#6ea4eb);
  }
  .flight_0 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 15px;
    padding:30px;
    margin-bottom: 20px;
    /* margin: 20px 20px; */
  }
  
  .flight_1 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
    width: 810px;
  }
  
  .flight_2 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
    min-height: 370px;
  }
  
  .cold {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 138px;
    height: 32px;
    cursor: pointer;
  }
  
  .cold:hover {
    color: #008cff !important;
    border: 1px solid #008cff !important;
    background-color: #eaf5ff !important;
  }
  
  .bus {
    background: #80808014;
    padding: 18px;
    border-radius: 15px;
  }
  
  .bus-seat {
    font-size: 54px;
    cursor: pointer;
  }
  
  .bus-seat:hover {
    color: #8b3eea;
  }
  
  .availble-seat {
    color: #7db3e9;
  }
  
  .booked-seat {
    color: #3072b6;
  }
  
  .chota-text {
    font-size: 12px;
    color: grey;
  }
  
  .train-ticket {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px;
    border-radius: 12px;
    width: 195px !important;
    cursor: pointer;
  }
  
  .single-train {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(138, 107, 118, 0.08);
    border: 0.7px solid #bababa;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px 20px 14px;
  }
  
  .train-book-status {
    width: 200px;
    border: 1px solid #00000036;
    padding: 10px;
    border-radius: 10px;
  }
  .destinationdetails {
    top: 20px;
    margin-top: -108px;
    color: white;
    position: relative;
}
  .aadfdf_0 {
    padding: 0px;
    border: 1px solid #00000042;
    border-radius: 4px;
  }
  
  .asbdg_0 {
    width: 800px;
  }
  
  .hjajhda-1 {
    width: 104px;
    height: 36px;
    padding: 7px;
    border-radius: 96px;
    background: transparent;
    color: #3072b6;
    border: 1px solid #3072b6;
  }
  
  .train-1 {
    width: 800px;
    border: 1px solid #00000059;
    padding: 25px;
    border-radius: 4px;
  }
  
  .asdahgfh_1 {
    height: 110px;
    margin-bottom: 20px;
  }
  
  .ahshdg_q1 {
    border: 1px solid #7e7e7e;
    cursor: pointer;
    height: 56px;
  }
  
  .dfgdfg_21 {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .hgdhg_1 {
    border: 1px solid #00000054;
    border-radius: 12px;
    padding: 15px;
  }
  
  .fdgfdg {
    background: #93b5dc;
    margin: 0px;
    padding: 12px;
    border-bottom: 1px solid #0a0a0a5c;
    font-weight: bold;
  }
  
  .economy {
    margin-top: 20px;
  }
  
  .abcdefg {
    width: 300px;
    margin-right: 10px;
  }
  
  .gjkh {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .gjgh {
    display: flex;
    justify-content: space-between;
  }
  
  .sdsddasa {
    display: flex;
  }
  
  .kjkj {
    display: flex;
  }
  
  @media screen and (max-width: 768px) {
    .all-fliter-head {
      display: flex;
      flex-direction: column;
      padding: 15px 5px;
      height: auto;
      justify-content: center !important;
      align-items: center !important;
    }
  
    .gjkh {
      display: block;
    }
  
    .sdsddasa {
      display: block;
    }
  
    .kjkj {
      display: block;
    }
  
    .gjgh {
      display: block;
    }
  
    .res-title {
      font-size: 19px;
      padding-bottom: 10px;
    }
  
    .abcdefg {
      width: 100%;
      margin-right: 0px;
    }
  
    .sticky {
      width: 94%;
    }
  
    .flight_0 {
      display: flex !important;
      flex-direction: column;
    }
  
    .header-item {
      margin: 0px 5px;
      font-size: 15px;
    }
  
    .rout-box {
      display: flex !important;
      flex-direction: column;
    }
  
    .hotl-booking {
      padding-top: 0px;
    }
  
    .flightName-box {
      margin-bottom: 15px;
    }
  
    .box-1 {
      margin: auto !important;
    }
  
    .flight_1 {
      width: auto;
    }
  
    .flight-booking {
      padding-top: 0px;
    }
  
    .economy {
      margin-top: -22px;
    }
  
    .titcan {
      font-size: 18px;
    }
  
    .gst-box {
      flex-direction: column;
    }
  
    .per-det {
      text-align: center;
    }
  
    .refund {
      background-color: inherit !important;
    }
  
    /* hotelfilter */
    .card_imgg {
      text-align: center;
    }
  
    .card_img:hover {
      transform: scale(1.1) !important;
    }
  
    .hotel-filter-img {
      margin-bottom: 15px;
    }
  
    .box-2 {
      flex-direction: column;
    }
  
    .sadfjsdjfdgfgh {
      display: block;
      margin-top: 20px;
    }
  
    .ffffsdfds {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .hvx {
        /* display: inline-block; */
        /* vertical-align: middle; */
        /* transform: perspective(1px) translateZ(0); */
        /* box-shadow: 0 0 1px rgba(0, 0, 0, 0); */
        /* position: relative; */
        background: #dddde4;
        /* transition-property: color; */
        /* transition-duration: 0.3s; */
        width: 200px;
        padding: 12px;
        text-align: center;
        color: rgb(22, 16, 16);
        border-radius: 15px;
      }
    /*  */
  
    .all-fliter-head {
      height: 84px;
      margin-right: 35px;
      flex-direction: column;
      padding: 4px;
      width: 100%;
    }
  
    .srch {
      margin-right: 32px !important;
    }
  
    .train-1 {
      width: 100%;
    }
  
    .continfo {
      width: 100% !important;
    }
  
    .pincode_state {
      width: 100% !important;
    }
  
    .pay_book {
      justify-content: center !important;
    }
  
    .flight_0 {
      flex-direction: column;
    }
  
    .f_pincode {
      flex-direction: column;
    }
  
    .f_pers_dets {
      flex-direction: column;
    }
  
    .f_pers_dets p {
      width: auto !important;
    }
  
    .f_free_canc {
      gap: 21px;
    }
  
    /* gk */
    .hgerwew_1 {
      margin-top: 20px;
      font-size: 12px;
    }
  
    .jhdfdf_0 {
      gap: 10px;
    }
  
    .jhdfdf_0 p {
      font-size: 12px;
    }
  
    .hsghdf_0 {
      background: #fff !important;
    }
  
    .ljdhf_0 {
      flex-flow: wrap;
    }
  
    .asdahgfh_1 {
      height: auto;
    }
  
    .khsgdf_0 {
      flex-wrap: wrap;
    }
  
    .masdjg_left {
      margin-left: 0px !important;
    }
  
    .hdgjfjs_1 {
      flex-direction: column;
    }
  
    .uydfgd_1 {
      gap: "5px";
    }
  
    .hotel-benfit {
      font-size: 12px;
    }
  }