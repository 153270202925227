.navigation {
  /* position: fixed; */
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0.5rem 0rem;
  background: #004aad;

  box-shadow: 0 2px 2px 2px rgb(9 9 9 / 23%);
  z-index: 999;
  top: 0;
}
.dropbtn {
  color: white;
  font-weight: 700;
  font-size: 17px;
  font-family: "DM Sans", sans-serif;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #004aad;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 7px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #004bab;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.header-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-d {
  font-size: 30px;
  color: #004bab;
}
.wraperr-f {
  display: flex;
  align-items: center;
  gap: 30px;
}
.time-d {
  font-size: 13px;
  font-weight: 800;
  font-family: system-ui;
}
.time-text {
  font-size: 13px;
  font-weight: 700;
  color: #333;
}
.brand-name {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 800;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin-top: 3px;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 25px;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #357997;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* position: absolute; */
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #357997;
}

@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
    margin-top: 25px;
    margin-left: 15px;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 73px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }

  /* .navigation-menu li {
    text-align: center;
    margin: 0;
  } */

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 10px 0;
  }

  .navigation-menu li:hover {
    background-color: #357997;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
  .navigation-menu li a {
    color: white;
    width: 100%;
    padding: 10px 0;
  }
  .navigation-menu.expanded ul {
    display: block;
    height: 438px;
    background-color: black;
  }
}
